/*.loading .image,*/
/*.loading p,*/
/*.loading .description,*/
/*.loading h1 {*/
/*    background-color: #ededed;*/
/*    background: linear-gradient(*/
/*            100deg,*/
/*            rgba(255, 255, 255, 0) 40%,*/
/*            rgba(255, 255, 255, .5) 50%,*/
/*            rgba(255, 255, 255, 0) 60%*/
/*    ) #ededed;*/
/*    background-size: 200% 100%;*/
/*    background-position-x: 180%;*/
/*    animation: 1s loading ease-in-out infinite;*/
/*}*/

/*@keyframes loading {*/
/*    to {*/
/*        background-position-x: -20%;*/
/*    }*/
/*}*/

/*.loading h1 {*/
/*    min-height: 1.6rem;*/
/*    border-radius: 4px;*/
/*    animation-delay: .05s;*/
/*}*/

/*.loading .description {*/
/*    min-height: 4rem;*/
/*    border-radius: 4px;*/
/*    animation-delay: .06s;*/
/*}*/
.post{
   position: relative;
   cursor: zoom-in;
}
/*.post:hover img::before{*/
/*   content:'123456';*/
/*   position: relative;*/
/*   top:0;*/
/*   left: 0;*/
/*   width: 100%;*/
/*   height: 100%;*/
/*   background-color: rgba(38,38,38,0.7);*/
/*}*/
.maskImage{
   visibility: hidden;
   position: absolute;
   top:0;
   left:0;
   width: 100%;
   height: 100%;
   background-color: rgba(38,38,38,0.5);
}
.post:hover .likeAndColl, .post:hover .creatorAndDownLoad , .post:hover .maskImage{
   visibility: visible;
}

.likeAndColl{
   position: absolute;
   top:0;
   display: flex;
   justify-content: flex-end;
   padding: 7px;
   width: 100%;
   /*display: none;*/
   visibility: hidden;
}
.likeAndColl .icon, .creatorAndDownLoad .icon{
   width: 35px;
   height: 35px;
   border-radius: 7px;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 2px;
   background-color: rgba(191,191,191,0.7);
   margin-right: 5px;
   cursor:pointer
}
.likeAndColl .icon span, .creatorAndDownLoad .icon span{
   color:#434343
}
.likeAndColl .icon:hover, .creatorAndDownLoad .icon:hover{
   background-color: #fff;
}
.likeAndColl .icon span:hover, .creatorAndDownLoad .icon span:hover{
   color:#141414
}

.likeAndColl .icon:hover .likeButton{
   color:#ff7875
}

.likeAndColl .icon:hover .collectionButton{
   color:#ffec3d
}

.creatorAndDownLoad .downLoad .icon:hover .downloadButton{
   color:#03c8a8;
}

.creatorAndDownLoad{
   display: none;
   position: absolute;
   bottom: 0;
   left:0;
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 7px;
   visibility: hidden;
   /*align-items: center;*/
}

.creatorAndDownLoad .creator{
 display: flex;
   align-items: center;
}
.creatorAndDownLoad .creator span{
   margin-left: 5px;
   color: #bfbfbf;
   cursor: pointer;
}
.creatorAndDownLoad .creator span:hover{
   color: #fff
}


.loginContent{
   display: flex;
   /*width: 600px;*/
   /*height: 400px;*/
}
.loginModal .ant-modal-content{
   width: 900px;
   height: 630px;
   padding: 0;
}

