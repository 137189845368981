@import url('https://fonts.font.im/css?family=Lobster');

.custom-btn {
    width: 110px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}

.btn-4 {
    background-color: #4dccc6;
    background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
    line-height: 42px;
    padding: 0;
    border: none;
}
.btn-4:hover{
    background-color: #89d8d3;
    background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}
.btn-4 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
.btn-4:before,
.btn-4:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
    transition: all 0.3s ease;
}
.btn-4:before {
    height: 0%;
    width: .1px;
}
.btn-4:after {
    width: 0%;
    height: .1px;
}
.btn-4:hover:before {
    height: 100%;
}
.btn-4:hover:after {
    width: 100%;
}
.btn-4 span:before,
.btn-4 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
    transition: all 0.3s ease;
}
.btn-4 span:before {
    width: .1px;
    height: 0%;
}
.btn-4 span:after {
    width: 0%;
    height: .1px;
}
.btn-4 span:hover:before {
    height: 100%;
}
.btn-4 span:hover:after {
    width: 100%;
}

.btn-5 {
    width: 110px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;
    background: rgb(255,27,0);
    background: linear-gradient(0deg, rgba(255,27,0,1) 0%, rgba(251,75,2,1) 100%);
}
.btn-5:hover {
    color: #f0094a;
    background: transparent;
    box-shadow:none;
}
.btn-5:before,
.btn-5:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #f0094a;
    box-shadow:
            -1px -1px 5px 0px #fff,
            7px 7px 20px 0px #0003,
            4px 4px 5px 0px #0002;
    transition:400ms ease all;
}
.btn-5:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
}
.btn-5:hover:before,
.btn-5:hover:after{
    width:100%;
    transition:800ms ease all;
}


.btn-13 {
    background-color: #89d8d3;
    background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
    border: none;
    z-index: 1;
}
.btn-13:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: #4dccc6;
    background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
    box-shadow:
            -7px -7px 20px 0px #fff9,
            -4px -4px 5px 0px #fff9,
            7px 7px 20px 0px #0002,
            4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

.btn-13:hover {
    color: #fff;
}
.btn-13:hover:after {
    top: 0;
    height: 100%;
}
.btn-13:active {
    top: 2px;
}

.navContent{
    height:110px;
    width:100vw;
    display:flex;
    justify-content:space-between;
    position:fixed;
    top:0;
    z-index:700;
    background-color:white;
    border-bottom:1px solid white;
    box-shadow:0px 2px 5px #f5f5f5
}

